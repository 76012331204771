var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.EVENT,
      "hide-title-section": "",
      "modal-width": "60vw"
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "c-grid mb-2"
        }, [_c('div', {
          staticClass: "col-half"
        }, [_c('AppDate', {
          attrs: {
            "label": "Start"
          },
          model: {
            value: _vm.formData.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "startDate", $$v);
            },
            expression: "formData.startDate"
          }
        })], 1), _c('div', {
          staticClass: "col-half"
        }, [_c('AppDate', {
          attrs: {
            "label": "End"
          },
          model: {
            value: _vm.formData.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "endDate", $$v);
            },
            expression: "formData.endDate"
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [!_vm.selectedAllocationWindow ? _c('ButtonCreate', {
          attrs: {
            "disabled": !_vm.isValidAllocationWindow,
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateNewAllocationWindow
          }
        }) : _c('ButtonSave', {
          attrs: {
            "disabled": !_vm.isValidAllocationWindow,
            "loading": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfEditAllocationWindow
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v(" Cancel"), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };