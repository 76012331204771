var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "c-grid allocation_matrix"
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isLoading ? _c('c-progress', {
    staticClass: "col-full"
  }) : _vm._e()], 1), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.isLoading ? _c('div', {
    staticClass: "col-full allocation_matrix_home"
  }, [_c('table', [_c('thead', [_c('tr', {
    staticStyle: {
      "position": "sticky",
      "top": "0",
      "left": "0",
      "z-index": "9999"
    }
  }, [_c('th', [_c('div', {
    staticClass: "c-flex align-center",
    staticStyle: {
      "gap": "0.8rem",
      "flex-wrap": "wrap"
    }
  }, [_c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showOnlyCriticalLists
    },
    attrs: {
      "icon": _vm.$icons['CRITICAL_INVENTORY_TRACKING'],
      "tooltip": _vm.showOnlyCriticalLists ? 'Show all lists' : 'Show only critical lists',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showOnlyCriticalLists = !_vm.showOnlyCriticalLists;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showNeedDates
    },
    attrs: {
      "icon": _vm.$icons.need,
      "tooltip": _vm.showNeedDates ? 'Hide need dates' : 'Show need dates',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showNeedDates = !_vm.showNeedDates;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showAllocationWindow
    },
    attrs: {
      "icon": _vm.$icons[_vm.EventType.ALLOCATION_WINDOW],
      "tooltip": _vm.showAllocationWindow ? 'Hide Allocation window' : 'Show Allocation window',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showAllocationWindow = !_vm.showAllocationWindow;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showDeliveryOrders
    },
    attrs: {
      "icon": _vm.$icons[_vm.DocumentType.DELIVERY_ORDER],
      "tooltip": _vm.showDeliveryOrders ? 'Hide Delivery orders' : 'Show Delivery orders',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showDeliveryOrders = !_vm.showDeliveryOrders;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showMegaAllocationTypeGround
    },
    attrs: {
      "icon": _vm.$icons[_vm.MegaAllocationType.GROUND],
      "tooltip": _vm.showMegaAllocationTypeGround ? 'Hide MEGA allocations marked as GROUND' : 'Show MEGA allocations marked as GROUND',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showMegaAllocationTypeGround = !_vm.showMegaAllocationTypeGround;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showMegaAllocationTypeOnOrbit
    },
    attrs: {
      "icon": _vm.$icons[_vm.MegaAllocationType.ON_ORBIT],
      "tooltip": _vm.showMegaAllocationTypeOnOrbit ? 'Hide MEGA allocations marked as ON ORBIT' : 'Show MEGA allocations marked as ON ORBIT',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showMegaAllocationTypeOnOrbit = !_vm.showMegaAllocationTypeOnOrbit;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showMegaAllocationTypeTradeStudy
    },
    attrs: {
      "icon": _vm.$icons[_vm.MegaAllocationType.TRADE_STUDY],
      "tooltip": _vm.showMegaAllocationTypeTradeStudy ? 'Hide MEGA allocations marked as TRADE STUDY' : 'Show MEGA allocations marked as TRADE STUDY',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showMegaAllocationTypeTradeStudy = !_vm.showMegaAllocationTypeTradeStudy;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    class: {
      active: _vm.showArchivedMegas
    },
    attrs: {
      "icon": _vm.$icons.archived,
      "tooltip": _vm.showArchivedMegas ? 'Hide archived MEGAs' : 'Show archived MEGAs',
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": function ($event) {
        _vm.showArchivedMegas = !_vm.showArchivedMegas;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.print,
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.printAllocationMatrix
    }
  })], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('c-popover', {
    attrs: {
      "align": "top",
      "side": "right",
      "max-height": "600px"
    }
  }, [_c('ButtonMiniAction', {
    attrs: {
      "slot": "activator",
      "icon": "fa-duotone fa-solid fa-square-question",
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    slot: "activator"
  }), _c('div', {
    staticClass: "c-grid legend__popover",
    staticStyle: {
      "font-weight": "normal"
    }
  }, [_c('div', {
    staticClass: "col-full mb-2 font-weight-bold legend__popover-header pl-0"
  }, [_vm._v(" Alertable conditions being tracked per MEGA: ")]), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.PROJECTED_DATE_AFTER_NEED_DATE_ALERT)))]), _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" If "), _c('ul', [_c('li', [_vm._v("part selection has been made")]), _c('li', [_vm._v("selected part is called out on an OPEN Delivery order line item")]), _c('li', [_vm._v("line item has set a "), _c('code', [_vm._v("ECD")])]), _c('li', [_c('code', [_vm._v("ECD")]), _vm._v(" occurs after the "), _c('code', [_vm._v("need")]), _vm._v("date")])])]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "secondary--text",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("High")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.PROJECTED_DATE_AFTER_NEED_DATE_WARNING)))]), _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" If "), _c('ul', [_c('li', [_vm._v("part selection has been made")]), _c('li', [_vm._v("selected part is called out on an OPEN Delivery order line item")]), _c('li', [_vm._v("line item has set a "), _c('code', [_vm._v("ECD")])]), _c('li', [_c('code', [_vm._v("ECD")]), _vm._v(" occurs within "), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v("90d")]), _vm._v(" of the "), _c('code', [_vm._v("need")]), _vm._v("date ")])])]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "warning--text text--darken-4",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("Warning")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.LAUNCH_EVENT_MISSING_VEHICLE)))]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Occurs when the target Launch Event is missing a Vehicle association.")]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "warning--text text--darken-4",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("Warning")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.LAUNCH_EVENT_OUTSIDE_ALLOCATION_WINDOW)))]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Occurs when the target Launch Event is outside the allocation window (including extensions set by Waiver). ")]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "warning--text text--darken-4",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("Warning")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.LINE_ITEM_PLANNED_EXPIRATION)))]), _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" If "), _c('ul', [_c('li', [_vm._v("part selection has been made")]), _c('li', [_vm._v("selected part is called out on an OPEN Delivery order line item")]), _c('li', [_vm._v("line item has set a "), _c('code', [_vm._v("Planned expiration")]), _vm._v("date")]), _c('li', [_c('code', [_vm._v("Planned expiration")]), _vm._v(" occurs after the allocation window "), _c('code', [_vm._v("end")]), _vm._v(" date (or waiver date) ")])])]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "secondary--text",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("High")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.MISSING_ALLOCATION_WINDOW)))]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Occurs when the selected MEGA has not set a "), _c('code', [_vm._v("Start")]), _vm._v(" and "), _c('code', [_vm._v("End")]), _vm._v(" of a target window. ")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Setting these boundaries allows COSMIC to alert users of other constraints involving this window of time. ")]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "secondary--text",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("High")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.MISSING_NEED_DATE)))]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Occurs when COSMIC cannot find either an inherited need date (from a parent) or a specifically overridden need date. ")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Setting a "), _c('code', [_vm._v("need")]), _vm._v(" date allows COSMIC to alert users more specifically of upcoming dates. ")]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "secondary--text",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("High")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.NEED_DATE_ALERT)))]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" When a non-inherited (overriden) need date is set to a date greater than the allocation window end/waiver date. ")]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "secondary--text",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("High")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.NEED_DATE_WARNING)))]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" When an inherited (from its parent) need date is set to a date greater than the allocation window end/waiver date. ")]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "warning--text text--darken-4",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("Warning")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.PART_USAGE_LIFE_EXPIRATION)))]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" If a part selection has been made and the part selection's usage life expiration occurs AFTER the allocation window "), _c('code', [_vm._v("end")]), _vm._v(" date (or the waiver date) ")]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "secondary--text",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("High")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.RETURN_EVENT_MISSING_VEHICLE)))]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Occurs when the target Return Event is missing a Vehicle association.")]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "warning--text text--darken-4",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("Warning")])])], 1)], 1), _c('div', {
    staticClass: "col-full"
  }, [_c('AppHeader', [_vm._v(_vm._s(_vm.MegaAlertTypeDisplay.get(_vm.MegaAlertType.RETURN_EVENT_OUTSIDE_ALLOCATION_WINDOW)))]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Occurs when the target Return Event is outside the allocation window (including extensions set by Waiver). ")]), _c('c-list-group', [_c('c-list-group-item', {
    attrs: {
      "label-width": "75px"
    }
  }, [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Severity")]), _c('div', {
    staticClass: "warning--text text--darken-4",
    attrs: {
      "slot": "value"
    },
    slot: "value"
  }, [_vm._v("Warning")])])], 1)], 1)])], 1)], 1), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.currentlyHiddenMegas.length ? _c('div', {
    staticClass: "col-sixth"
  }, [_c('ButtonMiniAction', {
    staticClass: "allocation_matrix_toggle_button",
    attrs: {
      "icon": "fa-regular fa-eye",
      "tooltip": `Unhide ${_vm.currentlyHiddenMegas.length} MEGAs`,
      "icon-color": "var(--cosmic-text)",
      "icon-size": "18px",
      "tooltip-position": "right"
    },
    on: {
      "click": _vm.uiClickOfUnhideMegas
    }
  })], 1) : _vm._e()])], 1)]), _vm._l(_vm.transformedAssemblyTemplates, function (rootMegaAssemblyTemplate) {
    return _c('th', {
      key: rootMegaAssemblyTemplate.id,
      staticClass: "allocation_matrix__table_col_header"
    }, [_c('div', {
      staticStyle: {
        "position": "relative"
      }
    }, [_c('router-link', {
      staticClass: "monospace_font font-weight-bold",
      attrs: {
        "to": `/mega_allocation/${rootMegaAssemblyTemplate.id}`
      }
    }, [_vm._v(" " + _vm._s(rootMegaAssemblyTemplate._name) + " ")]), _c('v-slide-x-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [rootMegaAssemblyTemplate.attributes.isArchived ? _c('div', {
      staticClass: "mega_archived secondary white--text font-weight-bold text-xs-left px-2 py-1"
    }, [_c('c-icon-fa', {
      staticClass: "mr-2",
      attrs: {
        "icon": _vm.$icons.archived,
        "icon-size": "16px",
        "icon-color": "white"
      }
    }), _c('span', [_vm._v("Archived")])], 1) : _vm._e()]), _vm.findAlertsForRootAndChildren(rootMegaAssemblyTemplate).length ? _c('div', {
      staticClass: "c-flex justify-content-end mt-1"
    }, [_c('c-popover', {
      attrs: {
        "align": "top",
        "side": "left",
        "min-height": "150px"
      }
    }, [_c('ButtonMiniAction', {
      attrs: {
        "slot": "activator",
        "icon": _vm.$icons.warning,
        "icon-color": "var(--v-secondary-base)",
        "icon-size": "18px"
      },
      slot: "activator"
    }), _c('div', {
      staticClass: "alert__popover"
    }, _vm._l(_vm.findAlertsForRootAndChildren(rootMegaAssemblyTemplate), function (alert, index) {
      return _c('div', {
        key: index
      }, [alert.alertType === _vm.MegaAlertType.LAUNCH_EVENT_MISSING_VEHICLE ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "warning",
          "remove-margin-left": ""
        }
      }, [_c('div', [_vm._v(" Launch event on "), alert._event ? _c('span', {
        staticClass: "monospace_font"
      }, [_vm._v(_vm._s(alert._event._startDate))]) : _vm._e(), _vm._v(" has no Vehicle assignment. ")])]) : _vm._e(), alert.alertType === _vm.MegaAlertType.LAUNCH_EVENT_OUTSIDE_ALLOCATION_WINDOW ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "warning",
          "remove-margin-left": ""
        }
      }, [_c('div', [_vm._v("Launch event (start) occurs outside the allocation window.")]), _c('div', {
        staticClass: "ml-2 my-1"
      }, [_c('c-list-group', {
        attrs: {
          "width": "250px"
        }
      }, [alert._event ? _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Launch start")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(alert._event._startDate))])]) : _vm._e(), _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Window start")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(" " + _vm._s(_vm.calculateComputedWindowStartDate(rootMegaAssemblyTemplate)) + " ")])]), _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Window end")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(_vm.calculateComputedWindowEndDate(rootMegaAssemblyTemplate)))])])], 1)], 1)]) : _vm._e(), alert.alertType === _vm.MegaAlertType.LINE_ITEM_PLANNED_EXPIRATION ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "error",
          "remove-margin-left": ""
        }
      }, [_c('SingleLineDisplayPart', _vm._b({
        staticStyle: {
          "font-size": "14px"
        }
      }, 'SingleLineDisplayPart', {
        ...alert._partReference
      }, false)), _c('div', [alert._deliveryOrderLineItem ? _c('span', {
        staticClass: "monospace_font"
      }, [_vm._v(_vm._s(alert._deliveryOrderLineItem._deliveryOrderNumber))]) : _vm._e(), _vm._v(" has a planned expiry date before the allocation window ends. ")]), _c('div', {
        staticClass: "ml-2 my-1"
      }, [_c('c-list-group', {
        attrs: {
          "width": "250px"
        }
      }, [alert._deliveryOrderLineItem ? _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Planned exp")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(alert._deliveryOrderLineItem._plannedExpirationDate))])]) : _vm._e(), _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Window end")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(_vm.calculateComputedWindowEndDate(rootMegaAssemblyTemplate)))])])], 1)], 1)], 1) : _vm._e(), alert.alertType === _vm.MegaAlertType.MISSING_NEED_DATE ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "error",
          "remove-margin-left": ""
        }
      }, [_c('div', [_vm._v("Missing need date.")]), _c('div', [_c('ButtonGeneric', {
        attrs: {
          "small": "",
          "color": "movement",
          "icon": _vm.$icons.need
        },
        on: {
          "click": function ($event) {
            return _vm.uiClickOfUpdateNeed(rootMegaAssemblyTemplate);
          }
        }
      }, [_vm._v(" Assign a need date ")])], 1)]) : _vm._e(), alert.alertType === _vm.MegaAlertType.MISSING_ALLOCATION_WINDOW ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "error",
          "remove-margin-left": ""
        }
      }, [_c('div', [_vm._v("No Allocation window found.")]), _c('div', [_c('ButtonGeneric', {
        attrs: {
          "small": "",
          "color": "events",
          "icon": _vm.$icons.ALLOCATION_WINDOW
        },
        on: {
          "click": function ($event) {
            return _vm.uiClickOfAllocationWindow(rootMegaAssemblyTemplate);
          }
        }
      }, [_vm._v(" Assign an allocation window ")])], 1)]) : _vm._e(), alert.alertType === _vm.MegaAlertType.NEED_DATE_ALERT ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "error",
          "remove-margin-left": ""
        }
      }, [_c('div', [_vm._v(" Non-inherited (overriden) need date is set to a date greater than the allocation window end/waiver date. ")]), _c('div', {
        staticClass: "ml-2 my-1"
      }, [_c('c-list-group', {
        attrs: {
          "width": "250px"
        }
      }, [_c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Need")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(rootMegaAssemblyTemplate._needDate))])]), _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Window end")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(_vm.calculateComputedWindowEndDate(rootMegaAssemblyTemplate)))])])], 1)], 1), _c('div', [_c('ButtonGeneric', {
        attrs: {
          "small": "",
          "color": "movement",
          "icon": _vm.$icons.need
        },
        on: {
          "click": function ($event) {
            return _vm.uiClickOfUpdateNeed(rootMegaAssemblyTemplate);
          }
        }
      }, [_vm._v(" Edit need date ")])], 1)]) : _vm._e(), alert.alertType === _vm.MegaAlertType.NEED_DATE_WARNING ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "warning",
          "remove-margin-left": ""
        }
      }, [_c('div', [_vm._v(" Inherited need date is set to a date greater than the allocation window end/waiver date. ")]), _c('div', {
        staticClass: "ml-2 my-1"
      }, [_c('c-list-group', {
        attrs: {
          "width": "250px"
        }
      }, [_c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Inherited need")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(rootMegaAssemblyTemplate._computedNeedDate))])]), _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Window end")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(_vm.calculateComputedWindowEndDate(rootMegaAssemblyTemplate)))])])], 1)], 1)]) : _vm._e(), alert.alertType === _vm.MegaAlertType.PART_USAGE_LIFE_EXPIRATION ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "error",
          "remove-margin-left": ""
        }
      }, [_c('SingleLineDisplayPart', _vm._b({
        staticStyle: {
          "font-size": "14px"
        }
      }, 'SingleLineDisplayPart', {
        ...alert._partReference
      }, false)), _c('div', [_vm._v("Usage life is planned to expire before the allocation window ends.")]), _c('div', {
        staticClass: "ml-2 my-1"
      }, [_c('c-list-group', {
        attrs: {
          "width": "250px"
        }
      }, [alert._partReference ? _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Usage life exp")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(alert._partReference._usageLifeExpirationDate))])]) : _vm._e(), _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Window end")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(_vm.calculateComputedWindowEndDate(rootMegaAssemblyTemplate)))])])], 1)], 1)], 1) : _vm._e(), alert.alertType === _vm.MegaAlertType.PROJECTED_DATE_AFTER_NEED_DATE_WARNING ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "warning",
          "remove-margin-left": ""
        }
      }, [_c('SingleLineDisplayPart', _vm._b({
        staticStyle: {
          "font-size": "14px"
        }
      }, 'SingleLineDisplayPart', {
        ...alert._partReference
      }, false)), _c('div', [_vm._v("ECD occurs after it's computed need.")]), _c('div', {
        staticClass: "ml-2 my-1"
      }, [_c('c-list-group', {
        attrs: {
          "width": "250px"
        }
      }, [alert._deliveryOrderLineItem ? _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("ECD")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(alert._deliveryOrderLineItem._projectedDate))])]) : _vm._e(), alert._partReference ? _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Need")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(alert._partReference._computedNeedDate))])]) : _vm._e()], 1)], 1)], 1) : _vm._e(), alert.alertType === _vm.MegaAlertType.PROJECTED_DATE_AFTER_NEED_DATE_ALERT ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "error",
          "remove-margin-left": ""
        }
      }, [_c('SingleLineDisplayPart', _vm._b({
        staticStyle: {
          "font-size": "14px"
        }
      }, 'SingleLineDisplayPart', {
        ...alert._partReference
      }, false)), _c('div', [_vm._v(" ECD for "), alert._deliveryOrderLineItem ? _c('span', {
        staticClass: "monospace_font"
      }, [_vm._v(_vm._s(alert._deliveryOrderLineItem._deliveryOrderNumber))]) : _vm._e(), _vm._v(" occurs within 90d of it's computed need. ")]), _c('div', {
        staticClass: "ml-2 my-1"
      }, [_c('c-list-group', {
        attrs: {
          "width": "250px"
        }
      }, [alert._deliveryOrderLineItem ? _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("ECD")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(alert._deliveryOrderLineItem._projectedDate))])]) : _vm._e(), alert._partReference ? _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Need")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(alert._partReference._computedNeedDate))])]) : _vm._e()], 1)], 1)], 1) : _vm._e(), alert.alertType === _vm.MegaAlertType.RETURN_EVENT_MISSING_VEHICLE ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "warning",
          "remove-margin-left": ""
        }
      }, [_c('div', [_vm._v(" Return event on "), alert._event ? _c('span', {
        staticClass: "monospace_font"
      }, [_vm._v(_vm._s(alert._event._startDate))]) : _vm._e(), _vm._v(" has no Vehicle assignment. ")])]) : _vm._e(), alert.alertType === _vm.MegaAlertType.RETURN_EVENT_OUTSIDE_ALLOCATION_WINDOW ? _c('AppPageDescription', {
        staticStyle: {
          "font-size": "16px !important"
        },
        attrs: {
          "color": "warning",
          "remove-margin-left": ""
        }
      }, [_c('div', [_vm._v("Return event (start) occurs outside the allocation window.")]), _c('div', {
        staticClass: "ml-2 my-1"
      }, [_c('c-list-group', {
        attrs: {
          "width": "250px"
        }
      }, [alert._event ? _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Return start")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(alert._event._startDate))])]) : _vm._e(), _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Window start")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(" " + _vm._s(_vm.calculateComputedWindowStartDate(rootMegaAssemblyTemplate)) + " ")])]), _c('c-list-group-item', {
        attrs: {
          "label-width": "100px",
          "value-width": "150px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_vm._v("Window end")]), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(_vm._s(_vm.calculateComputedWindowEndDate(rootMegaAssemblyTemplate)))])])], 1)], 1)]) : _vm._e()], 1);
    }), 0)], 1)], 1) : _vm._e(), _c('div', {
      staticClass: "mega_allocation_type c-flex justify-content-end"
    }, [!rootMegaAssemblyTemplate.attributes.megaAllocationType ? _c('i', {
      staticClass: "font-weight-regular error--text"
    }, [_vm._v(" no allocation type set ")]) : _c('ButtonMiniAction', {
      attrs: {
        "icon": _vm.$icons[rootMegaAssemblyTemplate.attributes.megaAllocationType],
        "icon-size": "18px",
        "icon-color": "var(--cosmic-text)",
        "tooltip": `MEGA allocation type is ${rootMegaAssemblyTemplate.attributes.megaAllocationType}`
      },
      on: {
        "click": function ($event) {
          return _vm.uiClickOfUpdateMegaAllocationType(rootMegaAssemblyTemplate);
        }
      }
    })], 1), _c('v-slide-y-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [_vm.showNeedDates ? _c('div', {
      staticClass: "need_date"
    }, [!rootMegaAssemblyTemplate.computedNeedDate ? _c('i', {
      staticClass: "font-weight-regular error--text"
    }, [_vm._v(" no need set ")]) : _c('div', {
      staticClass: "c-flex justify-content-end"
    }, [_c('ButtonMiniAction', {
      attrs: {
        "icon": _vm.$icons.need,
        "icon-size": "12px",
        "icon-color": "var(--v-movement-base)",
        "tooltip": "Edit root need date"
      },
      on: {
        "click": function ($event) {
          return _vm.uiClickOfUpdateNeed(rootMegaAssemblyTemplate);
        }
      }
    }), _c('span', [_vm._v(" " + _vm._s(rootMegaAssemblyTemplate._computedNeedDate) + " ")])], 1)]) : _vm._e()]), _c('v-slide-y-transition', {
      attrs: {
        "hide-on-leave": ""
      }
    }, [_vm.showAllocationWindow ? _c('div', {
      staticClass: "allocation_window"
    }, [!_vm.findAllocationWindowEvent(rootMegaAssemblyTemplate) ? _c('i', {
      staticClass: "font-weight-regular error--text"
    }, [_vm._v(" no window assigned ")]) : _c('div', {
      staticClass: "c-flex justify-content-end"
    }, [_c('ButtonMiniAction', {
      attrs: {
        "icon": _vm.$icons[_vm.EventType.ALLOCATION_WINDOW],
        "icon-size": "12px",
        "icon-color": "var(--v-events-base)",
        "tooltip": "Edit allocation window"
      },
      on: {
        "click": function ($event) {
          return _vm.uiClickOfAllocationWindow(rootMegaAssemblyTemplate);
        }
      }
    }), _c('span', [_vm._v(" " + _vm._s(_vm.findAllocationWindowEvent(rootMegaAssemblyTemplate)._startDate) + " "), _c('span', {
      staticClass: "font-weight-regular"
    }, [_vm._v("to")]), _vm._v(" " + _vm._s(_vm.calculateComputedWindowEndDate(rootMegaAssemblyTemplate)) + " ")])], 1)]) : _vm._e()]), _c('div', {
      staticClass: "actions",
      staticStyle: {
        "position": "absolute",
        "top": "0",
        "right": "0.1em"
      }
    }, [_c('c-menu', {
      attrs: {
        "align-right": ""
      }
    }, [_c('ButtonMiniActionEllipsis', {
      attrs: {
        "slot": "activator",
        "icon-size": "18px",
        "icon-color": "var(--cosmic-text)"
      },
      slot: "activator"
    }), _c('c-menu-item', {
      attrs: {
        "icon": "fa-duotone fa-user-astronaut",
        "href": `/mega_allocation/${rootMegaAssemblyTemplate.id}`,
        "icon-size": "18px"
      }
    }, [_c('div', {
      staticClass: "c-flex no-gap align-center",
      staticStyle: {
        "justify-content": "space-between"
      }
    }, [_c('div', [_vm._v("Detailed MEGA view")]), _c('div', {
      staticStyle: {
        "width": "20px",
        "text-align": "right"
      }
    }, [_c('c-icon-fa', {
      attrs: {
        "icon": _vm.$icons.external_link,
        "icon-size": "12px"
      }
    })], 1)])]), _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.application_reporting,
        "href": `/reporting/mega_deliveries/${rootMegaAssemblyTemplate.id}`,
        "icon-size": "18px"
      }
    }, [_c('div', {
      staticClass: "c-flex no-gap align-center",
      staticStyle: {
        "justify-content": "space-between"
      }
    }, [_c('div', [_vm._v("MEGA Deliveries report")]), _c('div', {
      staticStyle: {
        "width": "20px",
        "text-align": "right"
      }
    }, [_c('c-icon-fa', {
      attrs: {
        "icon": _vm.$icons.external_link,
        "icon-size": "12px"
      }
    })], 1)])]), _c('hr'), !_vm.isReadOnly ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.edit,
        "icon-size": "18px",
        "icon-color": "var(--v-primary-base)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfUpdateMegaAllocationType(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Edit Allocation type ")]) : _vm._e(), !_vm.hasAllocationWindow(rootMegaAssemblyTemplate) && !_vm.isReadOnly ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons[_vm.EventType.ALLOCATION_WINDOW],
        "icon-color": "var(--v-events-base)",
        "icon-size": "18px"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfAllocationWindow(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Create an Allocation window ")]) : _vm.hasAllocationWindow(rootMegaAssemblyTemplate) && !_vm.isReadOnly ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons[_vm.EventType.ALLOCATION_WINDOW],
        "icon-color": "var(--v-events-base)",
        "icon-size": "18px"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfAllocationWindow(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Edit Allocation window ")]) : _vm._e(), !_vm.isReadOnly ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.need,
        "icon-size": "18px",
        "icon-color": "var(--v-delivery_matrix_items-base)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfUpdateNeed(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Edit need date ")]) : _vm._e(), !_vm.isReadOnly ? _c('hr') : _vm._e(), _c('hr'), !_vm.isReadOnly ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.clone,
        "icon-size": "18px",
        "icon-color": "var(--cosmic-text)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfCloneMega(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Clone ")]) : _vm._e(), !rootMegaAssemblyTemplate.attributes.isArchived && !_vm.isReadOnly ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.archived,
        "icon-size": "18px",
        "icon-color": "var(--cosmic-text)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.onClickOfArchiveMega(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Archive ")]) : _vm._e(), rootMegaAssemblyTemplate.attributes.isArchived && !_vm.isReadOnly ? _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.unarchive,
        "icon-size": "18px",
        "icon-color": "var(--v-secondary-base)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.onClickOfUnarchiveMega(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Unarchive ")]) : _vm._e(), _c('c-menu-item', {
      attrs: {
        "icon": _vm.$icons.clear,
        "icon-size": "18px",
        "icon-color": "var(--cosmic-text)"
      },
      on: {
        "c-click": function ($event) {
          return _vm.uiClickOfHideMega(rootMegaAssemblyTemplate);
        }
      }
    }, [_vm._v(" Hide ")])], 1)], 1)], 1)]);
  })], 2)]), _c('tbody', [_vm._l(_vm.filteredUniqAssemblyTemplateLeaves, function (leaf) {
    return _c('tr', {
      key: leaf.id,
      staticClass: "allocation_matrix__table_row"
    }, [_c('th', {
      staticClass: "allocation_matrix__table_row_header hover_cursor",
      attrs: {
        "tooltip": `${leaf._autoHardwareListName}: ${leaf._autoHardwareListDescription}
                
Double click to view item list information
`,
        "tooltip-position": "right"
      },
      on: {
        "dblclick": function ($event) {
          return _vm.uiClickOfViewAutoHardwareListInfo(leaf);
        }
      }
    }, [_c('div', {
      staticClass: "monospace_font font-weight-bold"
    }, [_vm._v(_vm._s(leaf._autoHardwareListName))]), _c('div', {
      staticClass: "text-truncate description"
    }, [_vm._v(_vm._s(leaf._autoHardwareListDescription))]), leaf.autoHardwareListIsCritical ? _c('div', [_c('c-icon-fa', {
      attrs: {
        "icon": _vm.$icons['CRITICAL_INVENTORY_TRACKING'],
        "icon-size": "12px",
        "icon-color": "var(--cosmic-text)",
        "tooltip": "List is marked critical",
        "tooltip-position": "bottom left"
      }
    })], 1) : _vm._e(), _c('div', {
      staticClass: "hover_helper"
    }, [_c('c-progress', {
      attrs: {
        "type": "linear",
        "height": "4px"
      }
    })], 1)]), _vm._l(_vm.transformedAssemblyTemplates, function (rootMegaAssemblyTemplate) {
      return _c('td', {
        key: rootMegaAssemblyTemplate.id,
        staticClass: "pa-1",
        class: _vm.generateChickletStatusCssClass(rootMegaAssemblyTemplate)
      }, [_vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId) && _vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts.length === 0 ? _c('div', {
        staticClass: "hover_cursor",
        attrs: {
          "tooltip": "Double click to select a part"
        },
        on: {
          "dblclick": function ($event) {
            _vm.uiClickOfEditAssemblyTemplatePart(_vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId));
          }
        }
      }, [_c('i', [_vm._v("No part information entered")])]) : _vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId) ? _c('div', [leaf.waiverDate ? _c('c-list-group', {
        attrs: {
          "width": "280px",
          "tooltip": `Waiver extends until ${leaf._waiverDate}`
        }
      }, [_c('c-list-group-item', {
        attrs: {
          "label-width": "140px",
          "value-width": "140px"
        }
      }, [_c('div', {
        attrs: {
          "slot": "label"
        },
        slot: "label"
      }, [_c('c-icon-fa', {
        attrs: {
          "icon": _vm.$icons[_vm.DocumentType.WAIVER],
          "icon-size": "11px",
          "icon-color": "var(--v-documents-base)"
        }
      }), _vm._v(" until ")], 1), _c('div', {
        attrs: {
          "slot": "value"
        },
        slot: "value"
      }, [_vm._v(" " + _vm._s(leaf._waiverDate) + " ")])])], 1) : _vm._e(), leaf.waiverDate ? _c('hr') : _vm._e(), _vm._l(_vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts, function (part, index) {
        return _c('div', {
          key: part.id,
          staticStyle: {
            "position": "relative"
          }
        }, [_c('c-list-group', {
          staticClass: "hover_cursor",
          attrs: {
            "tooltip": "Double click to update part selection"
          },
          on: {
            "dblclick": function ($event) {
              _vm.uiClickOfEditAssemblyTemplatePart(_vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId));
            }
          }
        }, [_c('c-list-group-item', [_c('div', {
          attrs: {
            "slot": "label"
          },
          slot: "label"
        }, [_vm._v("Drawing number")]), _c('div', {
          attrs: {
            "slot": "value"
          },
          slot: "value"
        }, [_vm._v(" " + _vm._s(part.partReference.drawingNumber || '-') + " ")])]), _c('c-list-group-item', [_c('div', {
          attrs: {
            "slot": "label"
          },
          slot: "label"
        }, [_vm._v("Serial number")]), _c('div', {
          attrs: {
            "slot": "value"
          },
          slot: "value"
        }, [_vm._v(" " + _vm._s(part.partReference.serialNumber || '-') + " ")])]), part.partReference.lastCalibrationDate ? _c('c-list-group-item', [_c('div', {
          attrs: {
            "slot": "label"
          },
          slot: "label"
        }, [_vm._v("Last calibration")]), _c('div', {
          attrs: {
            "slot": "value"
          },
          slot: "value"
        }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(part.partReference.lastCalibrationDate)) + " ")])]) : _vm._e(), part.partReference.shelfLifeExpirationDate ? _c('c-list-group-item', [_c('div', {
          attrs: {
            "slot": "label"
          },
          slot: "label"
        }, [_vm._v("Shelf life expiry")]), _c('div', {
          attrs: {
            "slot": "value"
          },
          slot: "value"
        }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(part.partReference.shelfLifeExpirationDate)) + " ")])]) : _vm._e(), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": "",
            "group": ""
          }
        }, [part.partReference.usageLifeExpirationDate ? _c('c-list-group-item', {
          key: "if_you_got_usage_expiry"
        }, [_c('div', {
          attrs: {
            "slot": "label"
          },
          slot: "label"
        }, [_vm._v("Usage expiration")]), _c('div', {
          staticStyle: {
            "position": "relative"
          },
          attrs: {
            "slot": "value"
          },
          slot: "value"
        }, [_c('span', {
          staticClass: "monospace_font",
          class: {
            'secondary--text': _vm.hasUsageExpirationAlert(part, _vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId))
          }
        }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(part.partReference.usageLifeExpirationDate)) + " ")])])]) : _vm._e()], 1), _vm.showNeedDates ? _c('c-list-group-item', [leaf.computedNeedDate && !leaf.needDate ? _c('div', {
          attrs: {
            "slot": "label"
          },
          slot: "label"
        }, [_vm._v("Inherited need")]) : _c('div', {
          attrs: {
            "slot": "label"
          },
          slot: "label"
        }, [_vm._v("Need")]), _c('div', {
          attrs: {
            "slot": "value"
          },
          slot: "value"
        }, [_c('span', {
          staticClass: "monospace_font",
          class: {
            'secondary--text': _vm.hasNeedDateAlertAlert(part, _vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId)),
            'warning--text text--darken-2': _vm.hasNeedDateWarningAlert(part, _vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId))
          }
        }, [_vm._v(" " + _vm._s(part.partReference.computedNeedDate ? _vm.$dateDisplay(part.partReference.computedNeedDate) : '-') + " ")])])]) : _vm._e()], 1), _c('v-slide-y-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.showDeliveryOrders && _vm.rawAllocationMatrix && leaf && leaf.autoHardwareListId ? _c('div', _vm._l(_vm.findDeliveryOrdersByPartReference(part.partReference), function (deliveryOrderDocument) {
          return _c('div', {
            key: deliveryOrderDocument.id,
            staticClass: "mb-1"
          }, [_c('div', {
            staticClass: "delivery_order c-flex align-center no-gap"
          }, [_c('router-link', {
            staticStyle: {
              "display": "contents"
            },
            attrs: {
              "to": `/delivery_orders/manage/${deliveryOrderDocument.id}#information`
            }
          }, [_c('c-icon-fa', {
            staticStyle: {
              "margin-right": "2px"
            },
            attrs: {
              "icon": _vm.$icons[_vm.DocumentType.DELIVERY_ORDER],
              "icon-color": "var(--cosmic-text)",
              "icon-size": "14px"
            }
          }), _c('span', {
            staticClass: "monospace_font",
            staticStyle: {
              "text-decoration": "underline"
            }
          }, [_vm._v(_vm._s(deliveryOrderDocument.number))])], 1)], 1), _vm.findChangeSetsByPartReferenceAndDocumentId(part.partReference, deliveryOrderDocument.id) ? _c('div', _vm._l(_vm.findChangeSetsByPartReferenceAndDocumentId(part.partReference, deliveryOrderDocument.id), function (cs) {
            return _c('c-list-group', {
              key: cs.id
            }, [cs.deliveryItemsListNumber ? _c('c-list-group-item', [_c('div', {
              staticClass: "text-xs-right",
              attrs: {
                "slot": "label"
              },
              slot: "label"
            }, [_vm._v("DIL #")]), _c('div', {
              attrs: {
                "slot": "value"
              },
              slot: "value"
            }, [_vm._v(" " + _vm._s(cs.deliveryItemsListNumber) + " ")])]) : _vm._e(), cs.shipperNumber ? _c('c-list-group-item', [_c('div', {
              staticClass: "text-xs-right",
              attrs: {
                "slot": "label"
              },
              slot: "label"
            }, [_vm._v("Shipper #")]), _c('div', {
              attrs: {
                "slot": "value"
              },
              slot: "value"
            }, [_vm._v(" " + _vm._s(cs.shipperNumber) + " ")])]) : _vm._e(), cs.projectedDate ? _c('c-list-group-item', [_c('div', {
              staticClass: "text-xs-right",
              attrs: {
                "slot": "label"
              },
              slot: "label"
            }, [_vm._v("ECD")]), _c('div', {
              class: {
                'warning--text text--darken-4': _vm.hasProjectedDateWarning(part, _vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId)),
                'secondary--text': _vm.hasProjectedDateError(part, _vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId))
              },
              attrs: {
                "slot": "value"
              },
              slot: "value"
            }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(cs.projectedDate)) + " ")])]) : _vm._e(), cs.plannedExpirationDate ? _c('c-list-group-item', [_c('div', {
              staticClass: "text-xs-right",
              attrs: {
                "slot": "label"
              },
              slot: "label"
            }, [_vm._v("Planned expiry")]), _c('div', {
              class: {
                'secondary--text': _vm.hasPlannedExpirationAlert(part, _vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId))
              },
              attrs: {
                "slot": "value"
              },
              slot: "value"
            }, [_vm._v(" " + _vm._s(_vm.$dateDisplay(cs.plannedExpirationDate)) + " ")])]) : _vm._e()], 1);
          }), 1) : _vm._e()]);
        }), 0) : _vm._e()]), part.partReference.notes ? _c('div', {
          staticClass: "notes"
        }, [_c('c-popover', {
          attrs: {
            "align": "top",
            "side": "left",
            "min-height": "100px"
          }
        }, [_c('ButtonMiniAction', {
          attrs: {
            "slot": "activator",
            "icon": _vm.$icons.notes,
            "icon-color": "var(--v-warning-darken2)",
            "tooltip-position": "right",
            "tooltip": "View notes",
            "icon-size": "14px"
          },
          slot: "activator"
        }), _c('div', {
          staticClass: "notes__popover pa-2"
        }, [!_vm.isAddingNote ? _c('AppBlockquote', {
          attrs: {
            "title": "Notes"
          }
        }, [_c('AppMarkdownDisplay', {
          attrs: {
            "markdown": part.partReference.notes
          }
        })], 1) : _vm._e(), _vm.isAddingNote ? _c('AppMarkdown', {
          model: {
            value: _vm.formDataNote,
            callback: function ($$v) {
              _vm.formDataNote = $$v;
            },
            expression: "formDataNote"
          }
        }) : _vm._e(), !_vm.isAddingNote ? _c('ButtonCreate', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.uiClickOfAddToNote(part.partReference);
            }
          }
        }, [_vm._v(" Update note ")]) : _vm._e(), _vm.isAddingNote ? _c('ButtonSave', {
          attrs: {
            "small": ""
          },
          on: {
            "click": _vm.onClickOfSaveNoteToPart
          }
        }) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.findChildTemplateFromRoot(rootMegaAssemblyTemplate, leaf.autoHardwareListId).parts.length !== index + 1 ? _c('hr', {
          staticClass: "my-1"
        }) : _vm._e()], 1);
      })], 2) : _vm._e()]);
    })], 2);
  }), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.filteredUniqAssemblyTemplateLeaves.length === 0 ? _c('tr', [_c('i', [_vm._v("No matching MEGAs found.")])]) : _vm._e()])], 2)])]) : _vm._e()]), _vm.showModalMegaPartEdit && _vm.selectedAssemblyTemplateToEdit ? _c('ModalMegaPartEdit', {
    attrs: {
      "assembly-template-id": _vm.selectedAssemblyTemplateToEdit.id
    },
    on: {
      "modal-close": _vm.resetJustModals,
      "saved": _vm.reset
    }
  }) : _vm._e(), _vm.showModalAutoHardwareListInfo && _vm.selectedMegaLeaf && _vm.selectedMegaLeaf.autoHardwareListId ? _c('ModalAutoHardwareListInfo', {
    attrs: {
      "id": _vm.selectedMegaLeaf.autoHardwareListId
    },
    on: {
      "modal-close": _vm.resetJustModals,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.resetJustModals.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm.selectedMegaLeaf && _vm.showModalEventAllocationWindow ? _c('ModalEventAllocationWindowCreateEdit', {
    attrs: {
      "assembly-template-id": _vm.selectedMegaLeaf.id
    },
    on: {
      "modal-close": _vm.resetJustModals,
      "saved": _vm.reset
    }
  }) : _vm._e(), _vm.showModalCloneMega && _vm.selectedMegaLeaf ? _c('ModalMegaClone', {
    attrs: {
      "assembly-template-id": _vm.selectedMegaLeaf.id
    },
    on: {
      "saved": _vm.reset,
      "modal-close": _vm.resetJustModals
    }
  }) : _vm._e(), _vm.selectedMegaLeaf && _vm.showModalAssemblyTemplateInfo ? _c('ModalAssemblyTemplateInfo', {
    attrs: {
      "assembly-template-id": _vm.selectedMegaLeaf.id
    },
    on: {
      "modal-close": _vm.reset
    }
  }) : _vm._e(), _vm.selectedMegaLeaf && _vm.showModalUpdateNeed ? _c('ModalMegaNeedUpdate', {
    attrs: {
      "assembly-template-id": _vm.selectedMegaLeaf.id
    },
    on: {
      "modal-close": _vm.resetJustModals,
      "saved": _vm.reset
    }
  }) : _vm._e(), _vm.selectedMegaLeaf && _vm.showModalUpdateMegaAllocationType ? _c('ModalMegaAllocationTypeUpdate', {
    attrs: {
      "assembly-template-id": _vm.selectedMegaLeaf.id
    },
    on: {
      "modal-close": _vm.resetJustModals,
      "saved": _vm.reset
    }
  }) : _vm._e(), _c('div', {
    staticClass: "allocation_matrix_home",
    attrs: {
      "id": "printContainer",
      "aria-hidden": "true"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };