var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": "var(--v-assembly_templates-base)",
      "hide-title-section": "",
      "modal-width": "75vw"
    },
    on: {
      "modal-close": _vm.emitModalClose,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('c-card', {
          attrs: {
            "start-collapsed": "",
            "border-color": "var(--v-item_instances-base)"
          }
        }, [_c('div', {
          attrs: {
            "slot": "title"
          },
          slot: "title"
        }, [_c('IconAutoHardwareList', {
          staticClass: "mr-2",
          attrs: {
            "icon-size": "18px"
          }
        }), !_vm.autoHardwareList ? _c('span', [_vm._v("Item listing")]) : _c('span', [_vm._v(" Selected Item listing "), _c('span', {
          staticClass: "monospace_font font-weight-bold"
        }, [_vm._v(_vm._s(_vm.autoHardwareList.name))]), _vm.autoHardwareList.description ? _c('small', {
          staticClass: "ml-1"
        }, [_vm._v(_vm._s(_vm.autoHardwareList.description))]) : _vm._e()])], 1), _vm.autoHardwareList ? _c('div', {
          staticClass: "c-grid mt-2"
        }, [_c('div', {
          staticClass: "col-third"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Name",
            "monospace": "",
            "value": _vm.autoHardwareList.name
          }
        })], 1), _c('div', {
          staticClass: "col-two-thirds"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Description",
            "value": _vm.autoHardwareList.description
          }
        })], 1), _vm.autoHardwareListDrawingNumbers.length ? _c('div', {
          staticClass: "col-third"
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Drawing number prefixes"
          }
        }, [_vm.autoHardwareList.drawingNumberPrefix && _vm.autoHardwareList.drawingNumberPrefix.length ? _c('ul', _vm._l(_vm.autoHardwareList.drawingNumberPrefix, function (dn) {
          return _c('li', {
            key: dn
          }, [_c('span', {
            staticClass: "monospace_font"
          }, [_vm._v(" " + _vm._s(dn) + " ")])]);
        }), 0) : _vm._e()])], 1) : _vm._e()]) : _vm._e()]), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": "",
            "group": ""
          }
        }, [!_vm.isFetching ? _c('div', {
          key: "data",
          staticClass: "c-grid my-2"
        }, [_vm.alertNoParts && _vm.autoHardwareList ? _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "warning",
            "remove-margin-left": ""
          }
        }, [_vm._v(" No parts were found on list "), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.autoHardwareList.name))]), _vm._v(". ")])], 1) : _vm.selectOptionsAutoHardwareListPartReferences.length ? _c('div', {
          staticClass: "col-half c-flex align-items-end"
        }, [_c('AppSelect', {
          staticClass: "part_select",
          attrs: {
            "disabled": _vm.shouldDisablePartSelection,
            "select-options": _vm.selectOptionsAutoHardwareListPartReferences,
            "label": "Other parts available to add based on Item listing",
            "monospace-selections": ""
          },
          scopedSlots: _vm._u([{
            key: "select-option",
            fn: function ({
              option
            }) {
              return [_vm.findPartReferenceFromId(option.value) ? _c('SingleLineDisplayPart', _vm._b({}, 'SingleLineDisplayPart', {
                ..._vm.findPartReferenceFromId(option.value)
              }, false)) : _vm._e()];
            }
          }], null, false, 3865064791),
          model: {
            value: _vm.formData.selectedPartReference,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "selectedPartReference", $$v);
            },
            expression: "formData.selectedPartReference"
          }
        })], 1) : _c('div', {
          staticClass: "col-full"
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "warning",
            "remove-margin-left": ""
          }
        }, [_vm._v("All parts selected.")])], 1)]) : _vm._e(), _vm.isFetching ? _c('c-progress', {
          key: "progress"
        }) : _vm._e()], 1), _vm.selectedPartReferences.length ? _c('div', {
          staticClass: "c-grid mb-2"
        }, _vm._l(_vm.selectedPartReferences, function (part) {
          return _c('c-card', {
            key: part.id,
            staticClass: "col-full mb-2",
            attrs: {
              "border-color": "var(--v-item_instances-base)",
              "start-collapsed": _vm.selectedPartReferences.length > 1
            }
          }, [_c('div', {
            attrs: {
              "slot": "title"
            },
            slot: "title"
          }, [_c('c-icon-fa', {
            staticClass: "mr-2",
            attrs: {
              "slot": "icon",
              "icon": _vm.$icons.part_reference,
              "icon-color": "var(--v-item_instances-base)",
              "icon-size": "16px"
            },
            slot: "icon"
          }), _c('SingleLineDisplayPart', _vm._b({}, 'SingleLineDisplayPart', {
            ...part
          }, false))], 1), _c('div', {
            attrs: {
              "slot": "after-title"
            },
            slot: "after-title"
          }, [_c('ButtonMiniActionRemoveFromList', {
            staticClass: "mr-2",
            attrs: {
              "tooltip": "Clear part selection"
            },
            on: {
              "click": function ($event) {
                return _vm.onClickOfClearPart(part);
              }
            }
          })], 1), _c('AppPartReferenceDisplay', {
            staticClass: "part_display",
            attrs: {
              "part-reference-id": part.id
            }
          }), _c('AppMarkdown', {
            attrs: {
              "label": "Update note"
            },
            model: {
              value: _vm.formData.notes[part.id],
              callback: function ($$v) {
                _vm.$set(_vm.formData.notes, part.id, $$v);
              },
              expression: "formData.notes[part.id]"
            }
          }), _c('ButtonClearSelection', {
            attrs: {
              "small": ""
            },
            on: {
              "click": function ($event) {
                return _vm.onClickOfClearPart(part);
              }
            }
          }, [_vm._v("Clear part selection")])], 1);
        }), 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": _vm.alertNoParts,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfSave
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        }, [_vm._v(" Cancel"), _c('small', {
          staticClass: "ml-2"
        }, [_vm._v("(esc)")])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };