var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-navigation-drawer', {
    staticClass: "the_side_nav",
    class: {
      'outage-error': _vm.outageAlertState === _vm.AlertType.ERROR,
      'outage-warning': _vm.outageAlertState === _vm.AlertType.WARNING
    },
    attrs: {
      "value": _vm.value,
      "app": "",
      "aria-orientation": "vertical",
      "disable-resize-watcher": ""
    },
    on: {
      "input": _vm.emitInput
    }
  }, [_c('v-list', {
    staticClass: "py-0"
  }, [_c('v-list-tile', {
    staticClass: "reporting_menu_selection",
    attrs: {
      "active-class": "",
      "to": "/reporting"
    },
    on: {
      "click": _vm.closeSideNav,
      "focus": _vm.focusEvent
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": "fad fa-chart-line",
      "icon-size": "21px",
      "icon-color": _vm.$route.path.startsWith('/reporting') ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Reporting")])], 1)], 1), _vm.currentUserHasPermission(_vm.UserPermission.LOGISTICS_DELIVERY_ORDER_ADMIN) ? _c('v-list-tile', {
    staticClass: "nav_item",
    class: {
      nav_item_active: _vm.isRouteDeliveryOrder
    },
    attrs: {
      "to": "/delivery_orders/search",
      "active-class": ""
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons[_vm.DocumentType.DELIVERY_ORDER],
      "icon-color": _vm.$route.path.startsWith(`/delivery_orders`) ? 'black' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "nav_content"
  }, [_vm._v("Delivery orders")])], 1)], 1) : _vm._e(), _vm.currentUserHasPermission(_vm.UserPermission.LOGISTICS_DELIVERY_MATRIX_ITEM_ADMIN) ? _c('v-list-tile', {
    staticClass: "nav_item",
    class: {
      nav_item_active: _vm.isRouteDeliveryMatrix
    },
    attrs: {
      "to": "/delivery_matrix",
      "active-class": ""
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons[_vm.EntityType.DELIVERY_MATRIX_ITEM],
      "icon-color": _vm.$route.path.startsWith(`/${_vm.EntityType.DELIVERY_MATRIX_ITEM}`) ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "nav_content"
  }, [_vm._v("Delivery matrix")])], 1)], 1) : _vm._e(), _vm.currentUserHasPermission(_vm.UserPermission.LOGISTICS_MEGA_ADMIN) ? _c('v-list-tile', {
    staticClass: "nav_item",
    class: {
      nav_item_active: _vm.$route.path.startsWith(`/allocation_matrix`)
    },
    attrs: {
      "to": "/allocation_matrix",
      "active-class": ""
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('div', {
    staticClass: "allocation_matrix"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": "fa-regular fa-bracket-square",
      "icon-size": "20px",
      "icon-color": "var(--cosmic-text)"
    },
    domProps: {
      "fixedWidth": false
    }
  }), _c('c-icon-fa', {
    attrs: {
      "icon": "fa-duotone fa-user-astronaut",
      "icon-size": "15px",
      "icon-color": "var(--v-item_instances-base)"
    },
    domProps: {
      "fixedWidth": false
    }
  }), _c('c-icon-fa', {
    attrs: {
      "icon": "fa-regular fa-bracket-square-right",
      "icon-size": "20px",
      "icon-color": "var(--cosmic-text)"
    },
    domProps: {
      "fixedWidth": false
    }
  })], 1)]), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "nav_content"
  }, [_vm._v("Allocation matrix")])], 1)], 1) : _vm._e(), _vm.currentUserHasPermission(_vm.UserPermission.LOGISTICS_MEGA_ADMIN) || _vm.currentUserHasPermission(_vm.UserPermission.LOGISTICS_DELIVERY_ORDER_ADMIN) ? _c('v-list-tile', {
    staticClass: "nav_item",
    class: {
      nav_item_active: _vm.$route.path.startsWith(`/item_listings`)
    },
    attrs: {
      "to": "/item_listings",
      "active-class": ""
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('IconAutoHardwareList')], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "nav_content"
  }, [_vm._v("Item listings")])], 1)], 1) : _vm._e(), _c('v-list', _vm._l(_vm.filteredTheSideNavigationMenu, function (item) {
    return _c('v-list-group', {
      key: item.displayName,
      ref: item.displayName.toLowerCase(),
      refInFor: true,
      attrs: {
        "prepend-icon": item.icon,
        "no-action": "",
        "dark": ""
      }
    }, [_c('v-list-tile', {
      attrs: {
        "slot": "activator",
        "active-class": ""
      },
      slot: "activator"
    }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(item.displayName))])], 1)], 1), _vm._l(item.children, function (subItem) {
      return _c('v-list-tile', {
        key: subItem.displayName,
        attrs: {
          "to": subItem.to,
          "active-class": "",
          "exact": "",
          "exact-active-class": ""
        },
        on: {
          "click": _vm.closeSideNav
        }
      }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(subItem.displayName))])], 1), _c('v-list-tile-action', [_c('c-icon-fa', {
        staticClass: "subgroup_item_icon",
        attrs: {
          "icon": subItem.icon,
          "icon-size": "21px",
          "icon-color": _vm.$route.path.startsWith(`${subItem.to}`) ? 'white' : `${subItem.textColor}`
        }
      })], 1)], 1);
    })], 2);
  }), 1), _c('v-list-tile', {
    staticClass: "nav_item change_log",
    attrs: {
      "tag": "div"
    },
    on: {
      "click": _vm.onClickOfShowChangeLog
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": "fa-duotone fa-wand-magic-sparkles",
      "icon-size": "20px",
      "icon-color": "var(--v-grey-base)"
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("View app updates")])], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "text-xs-center monospace_font primary white--text font-weight-bold app_version"
  }, [_vm._v("v" + _vm._s(_vm.appVersion))])], 1)], 1)], 1), _vm.showChangeLog ? _c('ModalChangeLog', {
    on: {
      "modal-close": function ($event) {
        _vm.showChangeLog = !_vm.showChangeLog;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };