/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./App.vue?vue&type=template&id=d33e643a&scoped=true"
import script from "./App.ts?vue&type=script&lang=ts&external"
export * from "./App.ts?vue&type=script&lang=ts&external"
import style0 from "./App.vue?vue&type=style&index=0&id=d33e643a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d33e643a",
  null
  
)

export default component.exports